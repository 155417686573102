import React, { useState, useEffect } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Button, TextField, Grid, IconButton, Alert} from "@mui/material";
import { ContentCopy } from '@mui/icons-material';
import {Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div/Div';
import { transaction, adminwallet } from 'backendServices/ApiCalls';
import {CircularProgress} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InputAdornment from '@mui/material/InputAdornment';

const validationSchema = yup.object({
  amount: yup
      .number('Enter investment amount')
      .required('amount is required'),
  password: yup
      .string('Enter your password')
      .required('Password is required'),
  hash: yup
  .string('Enter transaction hash')
  .required('Password is required'),
});
const Investment = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [alertData, setalertData] = React.useState({
    show:false,
    message:"",
    variant:"" 
  })
  const [adminwalletdata,setAdminWalletData]=useState([])
  const [loading,setLoading]=useState(true)

const WalletData =()=>{
  adminwallet((response) => {
  setAdminWalletData(response?.data?.data)
  console.log("adminwallet",response);
  setLoading(false)
    
    }, (error) => {
        console.log(error?.response?.data);
  setLoading(false)

    })
}


useEffect(()=>{
  WalletData();


},[])

  
  const handleCopy=()=>{
    navigator.clipboard.writeText(adminwalletdata?.enteries[1]?.keyvalue);
    setIsCopied(true);

    // Reset the state after a certain duration if needed
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    setalertData({
      show:true,
      message:'address copied to clipboard',
      variant:"success"
    })
  }

  const onSubmitForm = (amount,hash,password,setSubmitting,resetForm) => {
    let params = {
      amount,
      hash,
      type: 'investment',
      status: 'Pending',
      details: 'You invested an amount of $'+amount,
      password,
  }
     console.log('abc',amount)
    transaction(params, (response) => {
      console.log("respons",response)
      
      if( response?.data?.status === "error"){
        setalertData({
          show:true,
          message:response?.data?.message,
          variant:"error"
      }) 
          setSubmitting(false)
      }else if(response?.data?.status === "success"){

        setalertData({
          show:true,
          message:response?.data?.message,
          variant:"success"
      })
          setSubmitting(false)
          resetForm();

      }
     
  }, (error) => {
      console.log(error?.response?.data);
  })
}
  



if(loading){
  return  <Div
  sx={{
      display: 'flex',
      minWidth: 0,
      alignItems: 'center',
      alignContent: 'center',
      height: '100%',
  }}
>
  <CircularProgress sx={{m: '-40px auto 0'}}/>
</Div>
}
    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
        {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }
      <Grid item sm={5}>
      <JumboDemoCard title={"Deposit Address"}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
          
            <Box

                sx={{
                    display: 'flex',
                    flexDirection: 'column',                  
                    '& .MuiTextField-root': {width: '34ch'},
                }}
                alignItems="center"
            >
      <Alert severity="warning">{adminwalletdata?.enteries[2]?.keyvalue}</Alert>
         <Box
        component="img"
        sx={{
          marginTop:1,
          height: 155,
          width: 155,
          backgroundColor: 'white',
          
        }}
        alt="The house from the offer."
        src={adminwalletdata?.picturelink+adminwalletdata?.enteries[0]?.keyvalue}
      />
        <TextField 
               
        type='text'
        disabled
        value={adminwalletdata?.enteries[1]?.keyvalue}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopy}>
                {isCopied ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ContentCopy />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}

        />
   
            </Box>
             
        </JumboDemoCard>
      </Grid>
      <Grid item sm={5} >
        <JumboDemoCard  title={"Investment Form"}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
             <Formik
                        validateOnChange={true}
                        initialValues={{
                            amount: '',
                            hash: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            onSubmitForm(data.amount, data.hash, data.password, setSubmitting,resetForm);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

            <Box 

                sx={{
                    display: 'flex',
                    flexDirection: 'column',                  
                    '& .MuiTextField-root': {width: '34ch'},
                }}
                alignItems="center"
            >
                                  
                                   <Div sx={{mt: 2,}}>
                                    <JumboTextField
                                        fullWidth
                                        name="amount"
                                        label="Enter Amount"
                                        type="number"
                                    /></Div>
                                   <Div sx={{mt: 3,}}>
                                        <JumboTextField
                                        fullWidth
                                        name="hash"
                                        label="Enter Transaction Hash"
                                        type="text"
                                    /></Div>
                                   <Div sx={{mt: 3,mb:2}}>
                                        <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Enter Password"
                                        type="password"
                                    /></Div>

   



      
               

              
<LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Submit</LoadingButton>
            </Box>
            </Form>
             )}
             </Formik>
        </JumboDemoCard>
      </Grid>
      </Grid>
    );
};

export default Investment;








