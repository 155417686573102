import React from 'react'
import {matchRoutes, useLocation} from "react-router-dom";

export default function useRoutePathMatch(routes) {
    const location =  useLocation();
    const url = location.pathname;
    var res = false;
    routes?.map((data,i)=>{
        if(data?.path===url){
            res=true;
        }
    })
    return res;
}

