import React, {Suspense} from 'react';
import {Alert, IconButton, TextField} from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ContentCopy } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import { Fade } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { useState } from 'react';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Sidebar = () => {
    
    const {loginUserData} = useContext(CustomProvider)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setalertData({
            show:false,
            message:"",
            variant:""
        })
    };
    const referrallink = 'http://localhost:3000/signup/'+loginUserData.randomcode
    const [alertData, setalertData] = useState({
        show:false,
        message:"",
        variant:""
    })
    const handleCopy=()=>{
        navigator.clipboard.writeText(referrallink);
        setalertData({
            show:true,
            message:"Referral link copied to clipboard",
            variant:"success"
        })
      }
    
    return (
        <React.Fragment>

            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                <Button sx={{ml:3}} size="small" onClick={handleOpen} variant="contained" endIcon={<PersonAddAltIcon/>}>
                    Referral Link
                </Button>
                    <JumboVerticalNavbar translate items={menus}/>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout:1000,
                    }}
                   
                >
                    <Fade in={open}>
                        <Div sx={style} alignItems="center" >
                            <Typography id="transition-modal-title" variant="h4" component="h2">
                            Referral Link

                    <CloseIcon  onClick={handleClose}  sx={{float:'right',color:'white', '&:hover': {
            cursor: 'pointer'
        }}}/>
                            </Typography>
        {
            alertData.show && (<Alert severity="success">{alertData.message}</Alert>)
        }
                            <center>
            <TextField sx={{width:'30ch'}}
               
               label={''} 
               type='text'
               disabled
               value={referrallink}
               margin="normal"
               InputProps={{endAdornment: 
               <IconButton>
                 <ContentCopy onClick={handleCopy} />
               </IconButton>}}
                />
                </center>
                        </Div>
                    </Fade>
                </Modal>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);


    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
