import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { userpayouts } from 'backendServices/ApiCalls';
import { CircularProgress } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';

const columns = [
  {
      field: "id",
      headerName: "ID",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
  },

  {
      field: "amount",
      headerName: "Amount",  
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false, 
      renderCell: (params) => `$${params.value}`
 
  },
  {
      field: "createdat",
      headerName: "Date",  
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,    
  }

]

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
    }
}
}
}


const VISIBLE_FIELDS = ['sr', 'amount', 'status','date'];
const PayoutReports = () => {
  const [loading,setLoading]=useState(true)
  const [payoutdata,setPayoutData]=useState([])
  const ReferralData =()=>{

    setLoading(true)
    userpayouts((response) => {
      if(response?.data?.status === "success") {
          setPayoutData(response?.data?.data?.enteries)
          setLoading(false)
      }
      }, (error) => {
          console.log(error?.response?.data);
          setLoading(false) 
      })
  }
  console.log("payoutdata",payoutdata)
    
useEffect(()=>{
    console.log('useeffect')
    ReferralData();
},[])


const rows= payoutdata
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
      color: '#fff',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}
if(loading){
  return  <Div
  sx={{
      display: 'flex',
      minWidth: 0,
      alignItems: 'center',
      alignContent: 'center',
      height: '100%',
  }}
>
  <CircularProgress sx={{m: '-40px auto 0'}}/>
</Div>
}
  return (
    <JumboDemoCard
    title={"Payout Summary"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>
    <Box sx={{ height: 400, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default PayoutReports