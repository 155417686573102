import React, { useEffect, useState } from 'react';
import {CircularProgress, Grid} from "@mui/material";
import PortfolioBalance from 'app/shared/metrics/PortfolioBalance/PortfolioBalance';
import EarningExpenses from 'app/shared/metrics/EarningExpenses/EarningExpenses';
import MarketingCampaign from 'app/shared/widgets/MarketingCampaign/MarketingCampaign';
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Referralusers from 'app/shared/widgets/ReferralUsers/ReferralUsers';
import { roidata, referralusers, lasttransactions } from 'backendServices/ApiCalls';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import Div from '@jumbo/shared/Div/Div';

const Crypto = () => {
    const [userroidata,setUserRoiData]=useState([])
    const [lasttransactionsdata,setLastTransactionsData]=useState([])
    const [picturelink,setPictureLink]=useState([])
    const [referralusersdata,setReferralUsersData]=useState([])
    
    const {loginUserData,loading} = useContext(CustomProvider);
    let userData = loginUserData


    const RoiData =()=>{
        roidata((response) => {
            setUserRoiData(response?.data?.data)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    const TransactionData =()=>{
        lasttransactions((response) => {
            setLastTransactionsData(response?.data?.data?.enteries)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const ReferralUsers =()=>{
        referralusers((response) => {
            setReferralUsersData(response?.data?.data?.enteries)
            setPictureLink(response?.data?.data?.picturelink)
        }, (error) => {
            console.log(error?.response?.data);
        })
    } 
    
    

    useEffect(()=>{
        RoiData();
        ReferralUsers();
        TransactionData();
    },[])



    if(loading){
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
      }

    return (
        <Grid container spacing={3.75}>
             <Grid item xs={12} sm={6} lg={3}>
                
                <ObjectCountRevenue value={userData?.walletbalance} title='walletbalance' color="primary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>} vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.totaldeposit} title='tdeposit' color="secondary.main"  icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.totalpayout} title='tpayout' color="success.main"  icon={<RedeemIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.referralteam} title='referralusers' color="info.main"  icon={<GroupAddIcon fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} lg={6}>
                <PortfolioBalance totalroi={userroidata?.roiGroupData?.totalroi} weeklyroi={userroidata?.roiGroupData?.weeklyroi} monthlyroi={userroidata?.roiGroupData?.monthlyroi} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <EarningExpenses earning={userData?.totalearning} investment={userData?.totaldeposit}/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Referralusers picturelink={picturelink} referralusersdata={referralusersdata}/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <MarketingCampaign lasttransactionsdata={lasttransactionsdata}/>
            </Grid>
        </Grid>
    );
};

export default Crypto;
